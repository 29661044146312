import { ActionType, DoorStatus, StateType } from './global-state';

function appReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'DOOR_STATUS':
      return {
        ...state,
        doorStatus: action.payload === 'open' ? DoorStatus.OPEN : DoorStatus.CLOSED,
      };

    case 'DOOR_STATUS_ERROR':
      return {
        ...state,
        doorStatus: DoorStatus.UNKNOWN,
      };

    case 'TOGGLE_DOOR':
      return {
        ...state,
        doorStatus: action.payload === 'open' ? DoorStatus.OPEN : DoorStatus.CLOSED,
      };

    case 'LOGIN':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('username', action.payload.username);
      localStorage.setItem('userId', action.payload.userId);
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        username: action.payload.username,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        authenticated: false,
      };

    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('userId');
      return {
        ...state,
        authenticated: false,
        token: undefined,
        userId: undefined,
        username: undefined,
      };

    case 'CURRENT_PAN_TILT':
      return {
        ...state,
        panTilt: {
          pan: action.payload.pan,
          tilt: action.payload.tilt,
        },
      };

    case 'CURRENT_PAN_TILT_ERROR':
      return {
        ...state,
        panTilt: undefined,
      };

    case 'TAKE_SNAPSHOT':
      return {
        ...state,
        snapshot: action.payload,
      };

    case 'LAST_MOTION':
      return {
        ...state,
        lastMotion: action.payload,
      };

    case 'WEATHER':
      return {
        ...state,
        weather: action.payload.weather,
      };

    default:
      return { ...state };
  }
}

export default appReducer;
