import axios from 'axios';
import { Dispatch } from 'react';
import { Socket } from 'socket.io-client';
import { ActionType } from './global-state';

function getRequestConfig(token: string) {
  return {
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json',
    },
  };
}

async function getPanTilt(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.get('/api/hardware/current-pan-tilt', config);
    console.log('RES:', res.data);
    dispatch({
      type: 'CURRENT_PAN_TILT',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'CURRENT_PAN_TILT_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function getWeather(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.get('/api/hardware/weather', config);
    console.log('RES:', res.data);
    dispatch({
      type: 'WEATHER',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'WEATHER_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function getDoorStatus(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.get('/api/hardware/door', config);
    console.log('RES:', res.data);
    dispatch({
      type: 'DOOR_STATUS',
      payload: res.data.status,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'DOOR_STATUS_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function toggleDoor(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.post('/api/hardware/door', {}, config);
    console.log('RES:', res.data);
    dispatch({
      type: 'TOGGLE_DOOR',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'TOGGLE_DOOR_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function pan(dispatch: Dispatch<ActionType>, token: string, angle: number, socket: Socket) {
  // const config = getRequestConfig(token);

  try {
    socket.emit('pan', angle);
    // const res = await axios.post('/api/hardware/pan', { angle }, config);
    // console.log('RES:', res.data);
    dispatch({
      type: 'CAMERA_PAN',
      payload: angle, // res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'CAMERA_PAN_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function tilt(dispatch: Dispatch<ActionType>, token: string, angle: number, socket: Socket) {
  // const config = getRequestConfig(token);

  try {
    socket.emit('tilt', angle);
    // const res = await axios.post('/api/hardware/tilt', { angle }, config);
    // console.log('RES:', res.data);
    dispatch({
      type: 'CAMERA_TILT',
      payload: angle,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'CAMERA_TILT_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function resetPanTilt(dispatch: Dispatch<ActionType>, token: string, socket: Socket) {
  // const config = getRequestConfig(token);

  try {
    socket.emit('reset-pan-tilt');
    // const res = await axios.post('/api/hardware/reset-pan-tilt', {}, config);
    // console.log('RES:', res.data);
    dispatch({
      type: 'RESET_CAMERA_PAN_TILT',
      payload: 'ok',
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'RESET_CAMERA_PAN_TILT_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function takeSnapshot(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.post('/api/hardware/stillframe', {}, config);
    console.log('RES:', res.data);
    dispatch({
      type: 'TAKE_SNAPSHOT',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'TAKE_SNAPSHOT_ERROR',
      payload: err.response.data.error,
    });
  }
}

async function getLastMotion(dispatch: Dispatch<ActionType>, token: string) {
  const config = getRequestConfig(token);

  try {
    const res = await axios.get('/api/hardware/last-motion-event', config);
    console.log('RES:', res.data);
    dispatch({
      type: 'LAST_MOTION',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    dispatch({
      type: 'LAST_MOTION_ERROR',
      payload: err.response && err.response.data.error,
    });
  }
}

export {
  getPanTilt,
  getWeather,
  getDoorStatus,
  toggleDoor,
  pan,
  tilt,
  resetPanTilt,
  takeSnapshot,
  getLastMotion,
};
