import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import AppReducer from './app-reducer';

export type ActionType = {
  type:
    | 'DOOR_STATUS'
    | 'DOOR_STATUS_ERROR'
    | 'TOGGLE_DOOR'
    | 'TOGGLE_DOOR_ERROR'
    | 'CAMERA_PAN'
    | 'CAMERA_PAN_ERROR'
    | 'CAMERA_TILT'
    | 'CAMERA_TILT_ERROR'
    | 'RESET_CAMERA_PAN_TILT'
    | 'RESET_CAMERA_PAN_TILT_ERROR'
    | 'TAKE_SNAPSHOT'
    | 'TAKE_SNAPSHOT_ERROR'
    | 'LAST_MOTION'
    | 'LAST_MOTION_ERROR'
    | 'LOGIN'
    | 'LOGIN_ERROR'
    | 'LOGOUT'
    | 'CURRENT_PAN_TILT'
    | 'CURRENT_PAN_TILT_ERROR'
    | 'WEATHER'
    | 'WEATHER_ERROR';
  payload: any;
};

export enum DoorStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  UNKNOWN = 'unknown',
}

export type StateType = {
  authenticated: boolean;
  doorStatus?: DoorStatus;
  token?: string;
  userId?: number;
  username?: string;
  panTilt?: { pan: number; tilt: number };
  snapshot?: {
    imageName: string;
  };
  lastMotion?: {
    name: string;
    time: string;
  };
  weather?: {
    current: {
      temp: string;
      wind_speed: number;
      wind_deg: number;
      weather: [
        {
          description: string;
          icon: string;
          id: number;
          main: string;
        }
      ];
    };
  };
  moon?: {};
};

type AppProviderProps = { children: React.ReactNode };

const username = localStorage.getItem('username');
const userId = localStorage.getItem('userId');
const token = localStorage.getItem('token');

function isAuthenticated(username: string | null, userId: string | null, token: string | null) {
  return username !== null && userId !== null && token !== null;
}

let initialState: StateType = {
  authenticated: isAuthenticated(username, userId, token),
  doorStatus: DoorStatus.OPEN,
};

if (username) {
  initialState.username = username;
}
if (userId) {
  initialState.userId = Number(userId);
}
if (token) {
  initialState.token = token;
}

const AppContext = createContext<Partial<StateType>>(initialState);
const AppDispatchContext = createContext<Dispatch<ActionType> | undefined>(undefined);

function AppProvider({ children }: AppProviderProps) {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

function useAppState() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppState must be used with an AppProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used with an AppProvider');
  }
  return context;
}

export { AppProvider, useAppDispatch, useAppState };
