function Header() {
  return (
    <header>
      <div className="logo"></div>
      <h1>Garagey</h1>
    </header>
  );
}

export { Header };
