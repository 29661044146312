import { formatRelative } from 'date-fns';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../context/global-state';
import { getLastMotion } from '../context/hardware-context';

function Snapshot() {
  const { snapshot } = useAppState();

  return (
    <>
      {snapshot ? (
        <div className="snapshot-container">
          <h3>Snapshot</h3>
          <img
            src={`https://garagey.kneecapslap.com/images/${snapshot.imageName}`}
            alt="forced snapshot"
          />
        </div>
      ) : null}
    </>
  );
}

function LastMotion() {
  const appDispatch = useAppDispatch();
  const { token, lastMotion } = useAppState();

  useEffect(() => {
    if (token) {
      getLastMotion(appDispatch, token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <>
      {lastMotion && lastMotion.time && lastMotion.name ? (
        <div className="snapshot-container">
          <h3>Last Motion Event</h3>
          <img
            className="snapshot"
            src={`https://garagey.kneecapslap.com/images/${lastMotion.name}`}
            alt="Last motion"
          />
          <div className="time">{formatRelative(new Date(lastMotion.time), new Date())}</div>
        </div>
      ) : null}
    </>
  );
}

function LastMotionEvent() {
  return (
    <>
      <Snapshot />
      <LastMotion />
    </>
  );
}

export { LastMotionEvent };
