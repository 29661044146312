import axios from 'axios';
import { Dispatch } from 'react';
import { ActionType } from './global-state';

async function login(dispatch: Dispatch<ActionType>, username: string, password: string) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post('/api/auth/login', { username, password }, config);
    console.log('RES:', res.data);
    dispatch({
      type: 'LOGIN',
      payload: res.data,
    });
  } catch (err) {
    console.log('ERROR:', err);
    const error = err as Error;
    const {message} = error;
    dispatch({
      type: 'LOGIN_ERROR',
      payload: message,
    });
  }
}

async function logout(dispatch: Dispatch<ActionType>) {
  dispatch({
    type: 'LOGOUT',
    payload: null,
  });
}

export { login, logout };
