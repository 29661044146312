import axios from 'axios';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import './app.css';
import { Camera } from './components/camera';
import { Header } from './components/header';
import { LastMotionEvent } from './components/last-motion-event';
import { Login } from './components/login';
import { useAppDispatch, useAppState } from './context/global-state';
import { logout } from './context/user-context';

const socket = io('https://garagey.kneecapslap.com', {
  withCredentials: false,
});
socket.on('connect', () => {
  console.log(`Socket connected on socket id: ${socket.id}`);
});

const App = () => {
  const { authenticated, token } = useAppState();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    // Make a fetch to validate the token
    axios
      .post(
        '/api/auth/verify-token',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        }
      )
      .then((res) => {
        if (res.data.message !== 'token-ok') {
          logout(appDispatch);
        }
      })
      .catch((err) => {
        logout(appDispatch);
      });
  }, [appDispatch, token]);

  return (
    <>
      {authenticated ? (
        <>
          <Header />
          <Camera socket={socket} />
          <LastMotionEvent />
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default App;
