import { ChangeEvent, FormEvent, MouseEvent, useState } from 'react';
import { useAppDispatch } from '../context/global-state';
import { login } from '../context/user-context';

function Login() {
  const appDispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
    setUsername(event.target.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function handleLogin(event: FormEvent) {
    event.preventDefault();
    login(appDispatch, username, password);
  }

  function handleSubmitClick(event: MouseEvent) {
    event.preventDefault();
    login(appDispatch, username, password);
  }

  return (
    <form action="" onSubmit={handleLogin}>
      <input type="text" placeholder="username" onChange={handleUsernameChange} />
      <input type="password" placeholder="password" onChange={handlePasswordChange} />
      <input type="submit" onClick={handleSubmitClick} value="Login" />
    </form>
  );
}

export { Login };
