import React, { MouseEvent, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { useAppDispatch, useAppState } from '../context/global-state';
import {
  getDoorStatus,
  getPanTilt,
  getWeather,
  pan,
  resetPanTilt,
  takeSnapshot,
  tilt,
  toggleDoor,
} from '../context/hardware-context';

function niceTime(d: Date) {
  return d.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
}

function niceDate(d: Date) {
  return d.toLocaleDateString();
}

function degToCompass(num: number) {
  const val = Math.floor(num / 22.5 + 0.5);
  const compass = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return compass[val % 16];
}

interface CameraProps {
  socket: Socket;
}

function Camera({ socket }: CameraProps): JSX.Element {
  const appDispatch = useAppDispatch();
  const { panTilt, token, weather, doorStatus } = useAppState();
  const [panAngle, setPanAngle] = useState(0);
  const [tiltAngle, setTiltAngle] = useState(0);

  useEffect(() => {
    if (token) {
      getPanTilt(appDispatch, token);
      getWeather(appDispatch, token);
      getDoorStatus(appDispatch, token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  function handleToggleDoor(event: MouseEvent) {
    if (token) {
      toggleDoor(appDispatch, token);
    }
  }

  function handlePanLeft(event: MouseEvent) {
    if (token) {
      let newAngle = panAngle - 10;
      newAngle = newAngle < -90 ? -90 : newAngle > 90 ? 90 : newAngle;
      pan(appDispatch, token, newAngle, socket);
      setPanAngle(newAngle);
    }
  }

  function handlePanRight(event: MouseEvent) {
    if (token) {
      let newAngle = panAngle + 10;
      newAngle = newAngle < -90 ? -90 : newAngle > 90 ? 90 : newAngle;
      pan(appDispatch, token, newAngle, socket);
      setPanAngle(newAngle);
    }
  }

  function handleTiltUp(event: MouseEvent) {
    if (token) {
      let newAngle = tiltAngle + 10;
      newAngle = newAngle < -90 ? -90 : newAngle > 90 ? 90 : newAngle;
      tilt(appDispatch, token, newAngle, socket);
      setTiltAngle(newAngle);
    }
  }

  function handleTiltDown(event: MouseEvent) {
    if (token) {
      let newAngle = tiltAngle - 10;
      newAngle = newAngle < -90 ? -90 : newAngle > 90 ? 90 : newAngle;
      tilt(appDispatch, token, newAngle, socket);
      setTiltAngle(newAngle);
    }
  }

  function handleResetPanTilt(event: MouseEvent) {
    if (token) {
      resetPanTilt(appDispatch, token, socket);
    }
  }

  function handleTakeSnapshot(event: MouseEvent) {
    if (token) {
      takeSnapshot(appDispatch, token);
    }
  }

  return (
    <>
      <div className="camera">
        <div className="camera-view"></div>
      </div>
      <div className="controls">
        <div className="d-pad horiz center">
          <img src="/img/d-pad.svg" alt="d pad controller for camera" />
          <button id="camera-up" onClick={handleTiltUp}></button>
          <button id="camera-down" onClick={handleTiltDown}></button>
          <button id="camera-left" onClick={handlePanLeft}></button>
          <button id="camera-right" onClick={handlePanRight}></button>
        </div>
        <div className="control-buttons vert center">
          <button onClick={handleToggleDoor}>TOGGLE DOOR</button>
          <button onClick={handleResetPanTilt}>RESET PAN/TILT</button>
          <button onClick={handleTakeSnapshot}>TAKE SNAPSHOT</button>
        </div>
      </div>
      <div className="cam-info horiz">
        <div className="horiz center box">
          <div className="box-item horiz center">
            <h6 className="display">Door Status: {doorStatus}</h6>
          </div>
        </div>
        <div className="horiz center box">
          <div className="box-item horiz center">
            <h6 className="display">PAN:</h6>
            <h6 className="display">{panTilt ? panTilt.pan : 0}&deg;</h6>
          </div>
          <div className="box-item horiz center">
            <h6 className="display">TILT:</h6>
            <h6 className="display">{panTilt ? panTilt.tilt : 0}&deg;</h6>
          </div>
        </div>
        <div className="horiz center box">
          <div className="box-item horiz center">
            <h6 className="display">
              {niceDate(new Date())}, {niceTime(new Date())}
            </h6>
          </div>
        </div>
        <div className="horiz center box">
          <div className="box-item horiz center">
            <h6 className="display">{weather ? weather.current.temp : ''}&deg;F</h6>
          </div>
          <div className="box-item horiz center">
            {weather ? (
              <img
                className="icon"
                alt="weather icon"
                src={`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`}
              />
            ) : null}
            <h6 className="display">{weather ? weather.current.weather[0].description : ''}</h6>
          </div>
          {weather ? (
            <div className="box-item horiz center">
              <img className="icon" src="/img/wind.svg" alt="weather wind icon" />
              <h6 className="display">
                {degToCompass(weather.current.wind_deg)} {Math.floor(weather.current.wind_speed)}
                mph
              </h6>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export { Camera };
